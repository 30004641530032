import AppserverClass from '@/core/carabiMultiApi/appserver'
import GatewayBase from './gatewayBase'

import IFileStorage from '@/core/carabiMultiApi/file-storage'
import FileStorageAppserv from '@/core/carabiMultiApi/file-storage/appserver'
import FileStorageMclStorage from '@/core/carabiMultiApi/file-storage/mclstorage'
import FileStorageMclFiles from '@/core/carabiMultiApi/file-storage/mclfiles'

import IQueries from '@/core/carabiMultiApi/queries'
import QueriesAppserv from '@/core/carabiMultiApi/queries/appserver'
import QueriesNats from '@/core/carabiMultiApi/queries/nats'

import IAuth from '@/core/carabiMultiApi/auth'
import AuthAppserv from '@/core/carabiMultiApi/auth/appserver'
import AuthNats from '@/core/carabiMultiApi/auth/nats'

export default class Api {
    constructor(config) {
        this.config = config
        this.defaultProject = config.project
        this.authorised = false
        this.project = null
        this.token = null

        /** @type {GatewayBase} */
        this.gateway = null
        if (config.gateway) {
            console.log('init gateway',config.gateway)
            this.gateway = new GatewayBase()
            this.gateway.setAddress(config.gateway)
        }
        if (config.appserver) {
            console.log('init appserver',config.appserver)
            this.appserver = new AppserverClass(config.appserver, this.defaultProject)
        }

        /** @type IQueries */
        this.queries = null
        if (config?.api?.queries) {
            if (config.api.queries === "appserv") {
                console.log('init queries appserv',this.appserver)
                this.queries = new QueriesAppserv(this.appserver)
            }
            else if (config.api.queries === "gateway") {
                console.log('init queries nats',this.gateway)
                this.queries = new QueriesNats(this.gateway)
            } else {
                throw new Error("wrong config.api.queries")
            }
        } else {
            throw new Error("config.api.queries not set")
        }

        /** @type IFileStorage */
        this.fileStorage = null
        console.log('init fileStorage',config?.api?.fileStorage)
        if (config?.api?.fileStorage) {
            if (config.api.fileStorage === "appserv") {
                console.log('init fileStorage appserv',this.appserver)
                this.fileStorage = new FileStorageAppserv(this.appserver)
            }
            else if (config.api.fileStorage === "mclfiles") {
                console.log('init fileStorage nats',this.gateway)
                this.fileStorage = new FileStorageMclFiles(this.gateway)
            }
            else if (config.api.fileStorage === "mclstorage") {
                console.log('init fileStorage nats',this.gateway)
                this.fileStorage = new FileStorageMclStorage(this.gateway)
            } 
            else {
                throw new Error("wrong config.api.fileStorage")
            }
        } else {
            throw new Error("config.api.fileStorage not set")
        }

        /** @type IAuth */
        this.auth = null
        console.log('init auth',config?.api?.auth)
        if (config?.api?.auth) {
            if (config.api.auth === "appserv") {
                console.log('init auth appserv',this.appserver)
                this.auth = new AuthAppserv(this.appserver)
            }
            else if (config.api.auth === "gateway") {
                console.log('init auth nats',this.gateway)
                this.auth = new AuthNats(this.gateway)
            } else {
                throw new Error("wrong config.api.auth")
            }
        } else {
            throw new Error("config.api.auth not set")
        }
    }

    setToken(newToken, project=null) {
        //console.log('api setToken',newToken, project)
        this.token = newToken
        this.project = project
        if (this.gateway) {
            this.gateway.setToken(newToken, project || this.defaultProject)
        }
        if (this.appserver) {
            this.appserver.project = project
            this.appserver.authorized = !!newToken
            this.appserver.token      = newToken
        }
    }

    async login(login, password, project=null) {
        //Проект по-умолчанию
        if (!project) project = this.defaultProject
        let newToken = await this.auth.login(project, login, password)
        console.log("newToken",newToken)
        this.setToken(newToken,project)
    }

    async logout() {
        this.auth.logout().then(()=>{})
        this.setToken(null)
    }
}