import store from "@/core/store/store";
import popups from "@/core/components/popups"
import escapeXml from "@/core/utils/escapeXml"
import { GatewayError } from "@/core/carabiMultiApi/gatewayBase";

export let processError = function(err) {
    console.error('Global Error:',err instanceof GatewayError, err);
    let isDebug = store.state.config.config.debug;
    //let message = 'Произошла внутренняя ошибка';
    let message = null;
    //Просто строка
    if (isDebug && typeof(err)==='string') {
        message = 'Error:<br>'+err;
    }
    
    //Объект с аппсервера
    if (typeof(err)==='object' && err.SymbCode) {
        message = 'Произошла внутренняя ошибка в серверной части';
        if (isDebug && typeof(err.Message)==='string') {
            message = 'Server Error:<br>'+err.Message;
        }
        if (isDebug && err.SymbCode === 'QUERY_ERROR') {
            message = 'Query Error '+err.query+':<br>'+escapeXml(JSON.stringify(err.queryParams))+'<br>'+escapeXml(err.Message);
        }
        if (err.SymbCode === 'INVALID_TOKEN') {
            message = null;
            store.commit('session/clear');
        }
        if (err.SymbCode === 'QUERY_USER_ERROR') {
            message = err.Message;
        }
    
    //GatewayError
    } else if (err instanceof GatewayError) {
        if (err.name === "InvalidToken") {
            message = null;
            store.commit('session/clear')
        } else {
            message = 'Server Error:<br>'+err.message
        }

    //Объект JS эксепшн
    } else if (typeof(err)==='object' && typeof(err.message)==='string') {
        message = err.message
        if (message === "ResizeObserver loop limit exceeded") {
            message = null
        }
        if (!isDebug) {
            message = null
        }
    }

    return message;
};

export let showError = function(err) {
    let message = processError(err);
    if (!message) return;
    message = message.replace(/(?:\r\n|\r|\n)/g, '<br/>'); //nl2br
    popups.alert(message);
};
