export default async function() {
  let sw = navigator?.serviceWorker
  if (!sw) {
    console.log("Service Worker Not Found")
    return
  }
  let registrations = await sw.getRegistrations()
  console.log("sw registrations",registrations)
  for(let registration of registrations) { 
    await registration.unregister()
    console.log("Service Worker Unregistred")
  }
}
