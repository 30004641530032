export const version= '2.3';

import Vue from 'vue'
import store from './store/store'
//import views from '@/core/models/views.js'
//import './registerServiceWorker'
import deleteServiceWorker from './deleteServiceWorker'
deleteServiceWorker().then(()=>{})

import '../assets/flex.sass'
import '../assets/sass/styles.sass'
import i18n from './i18n'

import VueColumnsResizable from '../assets/vue-columns-resizable';
Vue.use(VueColumnsResizable);

import ResizeObserver from '@/core/components/resize-observer';
Vue.component('resize-observer',ResizeObserver);

// air-datepicker
import 'air-datepicker'
//import 'air-datepicker/dist/css/datepicker.css' - from src/assets/sass/styles.sass

// select2
import 'select2'
import 'select2/dist/css/select2.css'

// fancytree
import 'jquery.fancytree/dist/skin-awesome/ui.fancytree.css';  // CSS or LESS

// font awesome 5 (FONTS)
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/regular.css'
import '@fortawesome/fontawesome-free/css/solid.css'

//jquery dadata suggestions
import 'suggestions-jquery/dist/css/suggestions.css'
import 'suggestions-jquery'

//fancybox
import '@fancyapps/fancybox/dist/jquery.fancybox.css'
import '@fancyapps/fancybox'

// Vue2TouchEvents
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents, {
  touchClass:          'pos-touch', // Add an extra CSS class when touch start, and remove it when touch end.
                                    // This is a global config, and you can use v-touch-class directive
                                    // to overwrite this setting in a single component.
  tapTolerance:        10,  // default 10. The tolerance to ensure whether the tap event effective or not.
  swipeTolerance:      120,  // default 30. The tolerance to ensure whether the swipe event effective or not.
  longTapTimeInterval: 400  // default 400 in millisecond.
                            // The minimum time interval to detect whether long tap event effective or not.
})

// day-js
import 'dayjs/locale/ru'
import * as dayjs from 'dayjs'
import dayjsUtc from 'dayjs/plugin/utc'
import dayjsTz from 'dayjs/plugin/timezone'
import dayjsRelativeTimeUtc from 'dayjs/plugin/relativeTime'
import dayjsDuration from 'dayjs/plugin/duration'
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(dayjsUtc)
dayjs.extend(dayjsTz)
dayjs.extend(dayjsRelativeTimeUtc)
dayjs.extend(dayjsDuration)
dayjs.extend(dayjsCustomParseFormat)

// vue-moment
require('moment/locale/ru')
moment.updateLocale('ru', {
  calendar: {
    lastDay:  '[Вчера], dd, D MMM',
    sameDay:  '[Сегодня], dd, D MMM',
    nextDay:  '[Завтра], dd, D MMM',
    lastWeek: '[Последний], dd, D MMM GG',
    nextWeek: 'dddd, D MMM GG',
    sameElse: 'dd, D MMM GG'
  }
})
Vue.use(require('vue-moment'), {
  moment
})

// VueMeta
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

// Or as a directive
import {VueMaskDirective} from 'v-mask'
Vue.directive('mask', VueMaskDirective)

// directive 'v-focus' - autofocus on input
Vue.directive('focus', {
  inserted: (el) => {
    el.focus()
  }
})

//datepicker
import '@/assets/vue2-datepicker/index.scss'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru';
Vue.component('DatePicker', DatePicker);

//multiselect - https://vue-multiselect.js.org/#sub-getting-started
import '@/assets/vue-multiselect/multi-select.sass'
import '@/assets/vue-multiselect/multi-select-small.sass'
import MultiSelect from 'vue-multiselect'
Vue.component('MultiSelect', MultiSelect);

//Глобальный обработчик ошибок
import {showError} from '@/core/utils/errors'
Vue.config.errorHandler = (err, vm, info) => {
  //Ошибки в VUE
  showError(err);
};
window.addEventListener('error',(errorMsg, url, lineNumber)=>{
  //Ошибки JS
  showError(errorMsg);
});
window.addEventListener('unhandledrejection',(promRejEv) => {
  //Ошибки в promise
  showError(promRejEv.reason);
});

//CARABI
import ApiClass from '@/core/carabiMultiApi'
import ApiAdapter from '@/core/carabiMultiApi/adapter'

export let config = null;
/** @type ApiClass */
export let api = null;
/** @type ApiAdapter */
export let carabi = null;

export async function main (App) {

  await store.dispatch('config/load')
  config = store.state.config.config;
  console.log('config', config);

  if (config.debug) {
    //Vue.config.productionTip = false;
    Vue.config.performance = true;
    Vue.config.debug = true;
    Vue.config.devtools = true;
  }

  api = new ApiClass(config)
  carabi = new ApiAdapter(api)

  await store.dispatch('session/load')
  if (store.state.session.session) {
    let sess = store.state.session.session
    api.setToken(sess.token,sess.project)
  }
  
  new Vue({
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')  

}
